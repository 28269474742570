import { GpsFixed } from '@mui/icons-material';
import { Box, IconButton, Badge } from "@mui/material";

const Controls = ({ isMobile, centerMapHandler }) => (
    <Box sx={{ position: 'absolute', bottom: isMobile ? '125px' : '95px', right: '10px', zIndex: 1000 }}>
        <Badge sx={{ width: isMobile ? '44px' : 'initial', height: isMobile ? '44px' : 'initial', background: 'white', borderRadius: 1, borderWidth: 1 }}>
            <IconButton size='large' sx={{ width: '100%', p: '5px', '&:hover': { backgroundColor: 'transparent', }, '&.Mui-focusVisible': { backgroundColor: 'transparent' }, }} aria-label='center map button' onClick={centerMapHandler}>
                <GpsFixed fontSize={isMobile ? 'medium' : 'small'} />
            </IconButton>
        </Badge>
    </Box>
);

export default Controls;
